<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import receiptroComponent from "@/components/tabReceiptRoComponent";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";
export default {
  page: {
    title: "แก้ไขข้อมูลการชำระเงิน",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // receiptroComponent,
    DatePicker,
  },
  data() {
    return {
      rowsRopart: [],
      optionTypeDc: [
        {
          name: "บาท",
          type: "bath",
        },
        {
          name: "เปอรเซ็น",
          type: "per",
        },
      ],
      optionsRoWhtPer: [
        {
          name: "ไม่ระบุ",
          id: 0,
        },
        {
          name: "3%",
          id: 3,
        },
      ],
      wages: {
        name: "ไม่ระบุ",
      },
      status: "",
      form: false,
      loading: false,
      wageOptions: [],
      wageOptionsRo: [],
      nameState: null,
      wageItem: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
        isPremium: 0,
      },
      part: [],
      rowsProd: [],
      partItem: {
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
      },
      rowParts: [],
      roDcPer: 0,
      rowRoParts: [],
      rowRoWages: [],
      userBranchId: "",
      currentPage: 1,
      perPage: 10,
      totalpartNetPrices: "",
      totalpartPrices: "",
      totalpartDcPer: "",
      totalparttotalPrice: "",
      sumtotalDcPrice: "",
      saleType: "",
      totalwageNetPrices: "",
      totalwagePrices: "",
      totalwageDcPer: "",
      totalwagetotalPrice: "",
      sumtotalwageDcPrice: "",
      repairOrder: [],
      roNetPrice: "",
      roDcPrice: "",
      roVatPrice: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      filter: {
        nameTh: "",
        wpCode: "",
      },
      totalPage: "",
      roType: "",
      totalRows: 1,
      totalRecord: 0,
      pageOptions: [10, 25, 50, 100],
      selectMode: "single",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionTypeWp: [
        {
          id: 1,
          type: "W",
          nameTh: "ค่าแรง",
        },
        {
          id: 2,
          type: "P",
          nameTh: "ค่าอะไหล่",
        },
        {
          id: 3,
          type: "L",
          nameTh: "ของเหลว",
        },
        {
          id: 4,
          type: "OS",
          nameTh: "งานนอก",
        },
        {
          id: 6,
          type: "PROD",
          nameTh: "สินค้า",
        },
        {
          id: 5,
          type: "OTH",
          nameTh: "อื่นๆ",
        },
      ],
      fieldsWage: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "wageCode",
          sortable: true,
          label: "รหัสค่าแรง",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อค่าแรง(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่อค่าแรง(อังกฤษ)",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPart: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่ออะไหล่(อังกฤษ)",
        },
        {
          key: "year",
          sortable: true,
          label: "ปี",
        },
        {
          key: "sellPrice",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsLiquid: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่ออะไหล่(อังกฤษ)",
        },
        {
          key: "year",
          sortable: true,
          label: "ปี",
        },
        {
          key: "sellPrice",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsOs: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "osCode",
          sortable: true,
          label: "รหัสงานนอก",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่องานนอก(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่องานนอก(อังกฤษ)",
        },
        {
          key: "supplierNameEn",
          sortable: true,
          label: "ชื่อบริษัท",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsProd: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "prodCode",
          sortable: true,
          label: "รหัสสินค้า",
        },
        {
          key: "vehicleModelNameTh",
          sortable: true,
          label: "ชื่อสินค้า(ไทย)",
        },
        {
          key: "vehicleModelNameEn",
          sortable: true,
          label: "ชื่อสินค้า(อังกฤษ)",
        },
        {
          key: "vehicleBrandNameEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
      ],
      fieldsOth: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "extraCode",
          sortable: true,
          label: "รหัสงานอื่นๆ",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่องานอื่นๆ(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่องานอื่นๆ(อังกฤษ)",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      rowsWp: [],
      rowsP: [],
      rowsW: [],
      rowsWage: [],
      rowsPart: [],
      rowsLiquid: [],
      rowsOs: [],
      rowsOth: [],
      wpSelect: {
        totalPrice: 0,
        vatPer: appConfig.vat,
        vatPrice: 0,
        netPrice: 0,
        dcPrice: 0,
        dcPer: 0,
        grandTotalPrice: 0,
      },
      meth: {
        selectType: "",
      },
      title: "แก้ไขข้อมูลการชำระเงิน",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "ชำระเงิน",
          active: false,
          href: "/payments",
        },
        {
          text: "แก้ไขข้อมูลการชำระเงิน",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: "",
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      arrTest: [],
      branchMaster: [],
      rowsRo: [],
      totalRecords: 0,
      isHidden: false,
      branch: "",
      type: "",
      roId: "",
      serviceRo: [],

      delTotal: "",

      paymentId: this.$route.params.paymentId,
      paymentType: "",
      paymentMeth: "",
      // receipt: [
      //   {
      //     id: 1,
      //     receiptName: "ออกใบกำกับภาษีปกติ",
      //   },
      //   {
      //     id: 2,
      //     receiptName: "ออกใบกำกับภาษีเคลม Warranty",
      //   },
      // ],
      //   payment: [
      //     {
      //       id: 1,
      //       payName: "ชำระเงินสด",
      //       paymentType: "CASH",
      //     },
      //     {
      //       id: 2,
      //       payName: "เครดิต",
      //       paymentType: "CREDIT",
      //     },
      //     {
      //       id: 3,
      //       payName: "โอนจ่าย",
      //       paymentType: "CREDIT",
      //     },
      //   ],
      payment: [],
      userBranch: "",
      rows: [],
      overlayFlag: false,

      paymentCode: "",
      licensePlate: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      branchName: "",
      cusName: "",
      payCash: "ชำระเงินสด",
      payCredit: "เครดิต",
      rowsCustomer: [],
      rowsVehicle: [],
      vehicle: "",
      paymentDate: "",
      credit: "",
      dueDate: "",
      note: "",
      rowsDetailMeth: [],

      totalPay: "",

      totalPrice: "",
      dcPer: "",
      dcPrice: "",
      netPrice: "",
      vatPer: "",
      vatPrice: "",
      grandTotalPrice: "",
      paymentTotal: 0,
      paymentMethTotal: "",
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    this.getDataCustomer();
    this.getDataVehicle();
    this.getDataWagePart();
    this.getDetailPaymentMeth();
    //     const date = new Date();
    // date.setDate(date.getDate() + 7);

    // var d = new Date(),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // this.date = year + "-" + month + "-" + day;

    // const date = new Date();
    // d.setDate(d.getDate() + 7);
    // this.getDataWagePart();
  },
  created() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    calDeleteWp(rowsWp) {
      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) - parseFloat(rowsWp.netPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    calUpdateWp() {
      let pricePerOne = 0;
      let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      this.rowsWp.forEach((item) => {
        pricePerOne += parseFloat(item.netPrice);
        netPrices += parseFloat(item.totalPrice) - parseFloat(item.dcPrice);
        // dcPer += parseFloat(item.dcPer);
        // totalPrice += parseFloat(item.totalPrice) * item.amount;
      });
      this.wpSelect.netPrice = netPrices.toFixed(2);
      this.wpSelect.totalPrice = pricePerOne.toFixed(2);
      this.wpSelect.vatPrice = (this.wpSelect.netPrice / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice = netPrices + this.wpSelect.vatPrice;

      this.totalPrice = parseFloat(this.wpSelect.totalPrice);
      this.netPrice = parseFloat(this.totalPrice) - parseFloat(this.dcPrice);
      this.grandTotalPrice =
        parseFloat(this.netPrice) + parseFloat(this.vatPrice);
      // this.dcPer = (this.totalPrice / 100) * this.dcPer;
      this.dcPrice = (this.totalPrice / 100) * this.dcPer;
    },
    addWpPayment: function(selected) {
      useNetw
        .post("api/payment/detail/store", {
          paymentId: this.paymentId,
          partId:
            selected.partId != null && this.roType.type == "P"
              ? selected.partId
              : undefined,
          wageId: selected.wageId != null ? selected.wageId : undefined,
          osId: selected.osId != null ? selected.osId : undefined,
          liquidId:
            selected.partId != null && this.roType.type == "L"
              ? selected.partId
              : undefined,
          extraId: selected.extraId != null ? selected.extraId : undefined,
          prodId: selected.prodId != null ? selected.prodId : undefined,
          type: this.roType.type,
          amount: 1,
          pricePerOne:
            selected.price != null ? selected.price : selected.sellPrice,
          totalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          dcPer: 0,
          dcPrice: 0,
          netPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          vatPer: this.wpSelect.vatPer,
          vatPrice: this.wpSelect.vatPrice,
          grandTotalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          paymentTotalPrice: this.wpSelect.totalPrice,
          paymentDcPer: this.dcPer,
          paymentDcPrice: this.wpSelect.dcPrice,
          paymentNetPrice: this.wpSelect.netPrice,
          paymentVatPer: this.vatPer,
          paymentVatPrice: this.wpSelect.vatPrice,
          paymentGrandTotalPrice: this.wpSelect.grandTotalPrice,
          isPremium: 0,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    handleSearch() {
      // this.getRo(this.branchIdRo);
      if (this.roType.type === "W") {
        this.getDataWage();
      } else if (this.roType.type === "P") {
        this.getDataPart();
      } else if (this.roType.type === "L") {
        this.getDataLiquid();
      } else if (this.roType.type === "OS") {
        this.getDataOs();
      } else if (this.roType.type === "OTH") {
        this.getDataOth();
      } else if (this.roType.type === "PROD") {
        this.getDataProd();
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else if (this.roType.type === "P") {
        this.getDataPart();
      } else if (this.roType.type === "L") {
        this.getDataLiquid();
      } else if (this.roType.type === "OS") {
        this.getDataOs();
      } else if (this.roType.type === "OTH") {
        this.getDataOth();
      } else if (this.roType.type === "PROD") {
        this.getDataProd();
      }
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else if (this.roType.type === "P") {
        this.getDataPart();
      } else if (this.roType.type === "L") {
        this.getDataLiquid();
      } else if (this.roType.type === "OS") {
        this.getDataOs();
      } else if (this.roType.type === "OTH") {
        this.getDataOth();
      } else if (this.roType.type === "PROD") {
        this.getDataProd();
      }
    },

    getDataPaymentMethod: function(branchId) {
      this.overlayFlag = true;
      useNetw
        .get("api/payment/payment-methods", {
          params: {
            page: 1,
            perPage: 100,
            branchId: branchId,
          },
        })
        .then((response) => {
          this.payment = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getDataWagePart: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/payment/detail", {
          params: {
            paymentId: this.paymentId,
          },
        })
        .then((response) => {
          this.rowsWp = response.data.data;
          this.rowsWp.forEach((element, index) => {
            if (element.type === "W") {
              this.rowsWp[index].selectWage = {
                wageId: element.wageId,
                nameTh: element.wageNameTh,
              };
            } else if (element.type === "P") {
              this.rowsWp[index].selectWage = {
                partId: element.partId,
                nameTh: element.partNameTh,
              };
            } else if (element.type === "L") {
              this.rowsWp[index].selectWage = {
                partId: element.liquidId,
                nameTh: element.liquidNameTh,
              };
            } else if (element.type === "OS") {
              this.rowsWp[index].selectWage = {
                osId: element.osId,
                nameTh: element.osNameTh,
              };
            } else if (element.type === "OTH") {
              this.rowsWp[index].selectWage = {
                extraId: element.extraId,
                nameTh: element.extraNameTh,
              };
            } else if (element.type === "PROD") {
              this.rowsWp[index].selectWage = {
                prodId: element.prodId,
                nameTh: `[${element.productCode != null ? element.productCode : '-'}] - ${element.productNameTh != null ? element.productNameTh : ''}`,
              };
            }
            // this.totalPricewage(this.rowsWp);
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataWp(type) {
      if (type.type === "W") {
        this.getDataWage();
      } else if (type.type === "P") {
        this.getDataPart();
      } else if (type.type === "L") {
        this.getDataLiquid();
      } else if (type.type === "OS") {
        this.getDataOs();
      } else if (type.type === "OTH") {
        this.getDataOth();
      } else if (type.type === "PROD") {
        this.getDataProd();
      }
    },
    getDataWage() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-wage", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            wageCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsWage = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataPart() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-part", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            partCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsPart = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataLiquid() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-liquid", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            partCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsLiquid = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataOs() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-os", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            osCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsOs = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataProd() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-product", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchName.branchId,
            image: 0,
            // nameTh: this.filter.nameTh,
            // extraCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsProd = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataOth() {
      this.loading = true;
      useNetw
        .get("api/payment/branch-extra", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            extraCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsOth = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    // calTotalTax(){

    // },

    totalPricewage(wageItem) {
      // if (this.wageItem.dcMethod.type === "per") {
      wageItem.totalPrice = parseFloat(wageItem.pricePerOne) * wageItem.amount;
      wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      wageItem.dcPrice =
        (parseFloat(wageItem.totalPrice).toFixed(2) / 100) * wageItem.dcPer;
      wageItem.netPrice =
        parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      wageItem.grandTotalPrice =
        parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      // wageItem.vatPer =
      // parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      wageItem.vatPrice = parseFloat(wageItem.netPrice / 100) * this.vatPer;
      this.calUpdateWp();

      // this.calUpdateWp(wageItem);
      // this.wpSelect.vatPrice =
      // (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      // let pricePerOne = 0;
      // let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      // let sum = 0;
      // this.rowsWp.forEach((item) => {
      //   pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //   netPrices += parseFloat(item.netPrice);
      //   dcPer += parseFloat(item.dcPer);
      //   totalPrice += parseFloat(item.totalPrice) * item.amount;
      //   sum += (parseFloat(item.totalPrice) / 100) * item.dcPer;
      // });
      // this.totalwageNetPrices = netPrices.toFixed(2);
      // this.totalwagePrices = pricePerOne.toFixed(2);
      // this.totalwageDcPer = dcPer.toFixed(2);
      // this.totalwagetotalPrice = totalPrice.toFixed(2);
      // this.sumtotalwageDcPrice = sum.toFixed(2);
      // } else if (this.wageItem.dcMethod.type === "bath") {
      //   wageItem.totalPrice =
      //     parseFloat(wageItem.pricePerOne).toFixed(2) * wageItem.amount;
      //   wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      //   wageItem.dcPrice = wageItem.dcPer;
      //   wageItem.netPrice =
      //     parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      //   let pricePerOne = 0;
      //   let netPrices = 0;
      //   let dcPer = 0;
      //   let totalPrice = 0;
      //   let sum = 0;
      //   this.rowRoWages.forEach(function(item) {
      //     pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //     netPrices += parseFloat(item.netPrice);
      //     dcPer += parseFloat(item.dcPer);
      //     totalPrice += parseFloat(item.totalPrice) * item.amount;
      //     sum += parseFloat(item.dcPer);
      //   });
      //   this.totalwageNetPrices = netPrices.toFixed(2);
      //   this.totalwagePrices = pricePerOne.toFixed(2);
      //   this.totalwageDcPer = sum.toFixed(2);
      //   this.totalwagetotalPrice = totalPrice.toFixed(2);
      //   this.sumtotalwageDcPrice = dcPer.toFixed(2);
      // }
    },
    calWpPrice(rowRo) {
      this.wpSelect.totalPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      this.wpSelect.netPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      // this.wpSelect.vatPrice =
      //   ((parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1) /
      //     100) *
      //   parseFloat(this.wpSelect.vatPer);

      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) +
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      this.calWpPrice(rowRo[0]);
      this.addWpPayment(rowRo[0]);
      this.$refs["modalSearch"].hide();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/payment/show", {
          params: {
            paymentId: this.paymentId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.paymentCode = response.data.data.paymentCode;
          this.roCode = response.data.data.roCode;
          this.paymentDate = response.data.data.paymentDate;
          this.totalPrice = response.data.data.totalPrice;
          this.dcPer = response.data.data.dcPer;
          this.dcPrice = response.data.data.dcPrice;
          this.netPrice = response.data.data.netPrice;
          this.vatPer = response.data.data.vatPer;
          this.vatPrice = response.data.data.vatPrice;
          this.grandTotalPrice = response.data.data.grandTotalPrice;
          this.vehicle = {
            licensePlate: response.data.data.licensePlate,
            vehicleId: response.data.data.vehicleId,
          };
          this.cusName = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.branchName = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };
          // this.paymentType = {
          //   pay_meth_id: response.data.data.payMethId,
          //   name_th: response.data.data.paymentMethodNameTh,
          // };
          this.status = response.data.data.status;
          this.getDataPaymentMethod(response.data.data.branchId);
          // this.paymentType = {paymentType: 'CREDIT', payName: "เครดิต",}
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updatePayment: function() {
      this.overlayFlag = true;
      useNetw
        .put("api/payment/update", {
          paymentId: this.paymentId,
          cusId: this.cusName.cusId,
          vehicleId: this.vehicle.vehicleId,
          paymentCode: this.paymentCode,
          paymentDate: this.paymentDate,
          payMethId:
            this.paymentType.pay_meth_id != null
              ? this.paymentType.pay_meth_id
              : undefined,
          note: this.note,
          totalPrice: this.totalPrice,
          dcPer: this.dcPer,
          dcPrice: this.dcPrice,
          netPrice: this.netPrice,
          vatPer: this.vatPer,
          vatPrice: this.vatPrice,
          grandTotalPrice: this.grandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataCustomer: function() {
      this.loading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsCustomer = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataVehicle: function() {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsVehicle = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    resetModal() {
      this.rowsRo.forEach((item, index) => {
        this.rowsRo[index].roId = item.roId;
        this.rowsRo[index].roId = null;
      });

    },
    alertDelete(rowsWp, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบรายการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.calDeleteWp(rowsWp);
            this.deleteDetailPayment(rowsWp, index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    alertDeletePayment(meth, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบรายการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            // this.caldelPayment(meth);
            this.deleteDetailPayment(meth, index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    updateWpPayment: function(rowsWp) {
      this.calUpdateWp(rowsWp);
      this.overlayFlag = true;
      useNetw
        .put("api/payment/detail/update", {
          paydId: rowsWp.paydId,
          partId:
            rowsWp.partId != null && rowsWp.type == "P"
              ? rowsWp.partId
              : undefined,
          wageId:
            rowsWp.wageId != null && rowsWp.type == "W"
              ? rowsWp.wageId
              : undefined,
          osId:
            rowsWp.osId != null && rowsWp.type == "OS"
              ? rowsWp.osId
              : undefined,
          liquidId:
            rowsWp.liquidId != null && rowsWp.type == "L"
              ? rowsWp.liquidId
              : undefined,
          extraId:
            rowsWp.extraId != null && rowsWp.type == "OTH"
              ? rowsWp.extraId
              : undefined,
          prodId:
            rowsWp.prodId != null && rowsWp.type == "PROD"
              ? rowsWp.prodId
              : undefined,
          type: rowsWp.type,
          amount: rowsWp.amount,
          pricePerOne: rowsWp.pricePerOne,
          totalPrice: rowsWp.totalPrice,
          dcPer: rowsWp.dcPer,
          dcPrice: rowsWp.dcPrice,
          netPrice: rowsWp.netPrice,
          vatPer: rowsWp.vatPer,
          vatPrice: rowsWp.vatPrice,
          grandTotalPrice: rowsWp.grandTotalPrice,
          paymentTotalPrice: this.wpSelect.totalPrice,
          paymentDcPer: this.dcPer,
          paymentDcPrice: this.dcPrice,
          paymentNetPrice: this.wpSelect.netPrice,
          paymentVatPer: this.vatPer,
          paymentVatPrice: this.wpSelect.vatPrice,
          paymentGrandTotalPrice: this.wpSelect.grandTotalPrice,
          isPremium: rowsWp.isPremium != null ? rowsWp.isPremium : 0,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getData();
          // this.$refs["modalAmout"].hide();
          // this.getDataShowPart();
          //   this.getPoPart(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    deleteWpPayment: function(rowsWp) {
      this.loading = true;
      useNetw
        .delete("api/payment/detail/delete", {
          data: {
            paydId: rowsWp.paydId,
            paymentTotalPrice: this.wpSelect.totalPrice,
            paymentDcPer: this.dcPer,
            paymentDcPrice: this.wpSelect.dcPrice,
            paymentNetPrice: this.wpSelect.netPrice,
            paymentVatPer: this.vatPer,
            paymentVatPrice: this.wpSelect.vatPrice,
            paymentGrandTotalPrice: this.wpSelect.grandTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    submitPayment() {
      this.loading = true;
      useNetw
        .put("api/payment/submit", {
          paymentId: this.paymentId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message + '<br>' + err.response.data.validationMessage),
            appConfig.swal.type.error
          );
          this.$router.push({name : "payments"})
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDetailPaymentMeth() {
      this.loading = true;
      useNetw
        .get("api/payment/method-detail", {
          params: {
            paymentId: this.paymentId,
          },
        })
        .then((response) => {
          this.rowsDetailMeth = response.data.data;
          this.rowsDetailMeth.forEach((element, index) => {
            this.rowsDetailMeth[index].selectType = {
              payMethdId: element.payMethdId,
              name_th: element.paymentMethodNameTh,
            };
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    addDetailPayment() {
      this.loading = true;
      useNetw
        .post("api/payment/method-detail/store", {
          paymentId: this.paymentId,
          payMethId: this.paymentMeth.pay_meth_id,
          totalPrice: this.paymentTotal,
          paymentTotal: this.paymentMethTotal.replace(/,/g, ""),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.$refs["modalAmout"].hide();
          this.getDetailPaymentMeth();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    updateDetailPayment(meth) {
      this.calUpdatePayment(meth);
      this.calPayment(meth.totalPrice);
      this.loading = true;
      useNetw
        .put("api/payment/method-detail/update", {
          payMethdId: meth.payMethdId,
          payMethId: meth.payMethId,
          totalPrice: meth.totalPrice,
          paymentTotal: this.totalPay,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    deleteDetailPayment(meth) {
      this.caldelPayment(meth);
      this.loading = true;
      useNetw
        .delete("api/payment/method-detail/delete", {
          data: {
            payMethdId: meth.payMethdId,
            paymentTotal: this.delTotal.replace(/,/g, ""),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getDetailPaymentMeth();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    calPayment(item) {
      let total = 0;
      if (this.rowsDetailMeth != "") {
        this.rowsDetailMeth.forEach((data) => {
          total += parseFloat(data.totalPrice) + parseFloat(item);
        });
      } else {
        total = parseFloat(item);
      }
      if (!isNaN(total)) {
        this.paymentMethTotal = this.numberFormats(total);
      }
    },
    caldelPayment(item) {
      let total = 0;
      this.rowsDetailMeth.forEach((data) => {
        total += parseFloat(data.totalPrice);
      });
      if (!isNaN(total)) {
        this.delTotal = this.numberFormats(
          parseFloat(total) - parseFloat(item.totalPrice)
        );
      }
    },
    calUpdatePayment(meth) {
      if (meth) {
        let totalPay = 0;
        this.rowsDetailMeth.forEach((data) => {
          totalPay += parseFloat(data.totalPrice); // + parseFloat(item);
        });
        this.totalPay = totalPay;
      }

      // if(!isNaN(total)) {
      // this.paymentMethTotal = this.numberFormats(total);
      // }
    },
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 140px;
  text-align: right;
}
.Dc {
  width: 100px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
          :show="dismissCountDown"
          dismissible
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
          <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-7">
                  <div class="row">
                    <!-- <div class="col-5 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ประเภทชำระ:
                        <multiselect
                          v-model="paymentType"
                          label="name_th"
                          :options="payment"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="cusName"
                          label="nameTh"
                          :options="rowsCustomer"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>เลขที่ใบชำระเงิน:
                        <b-form-input
                          v-model="paymentCode"
                          disabled
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ป้ายทะเบียน:
                        <multiselect
                          v-model="vehicle"
                          label="licensePlate"
                          :options="rowsVehicle"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>วันที่ออกใบชำระเงิน:
                        <date-picker
                          v-model="paymentDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-3 col-sm-6 col-md-2">
                      <div class="mb-3">
                        เครดิต:
                        <b-form-input
                          v-model="credit"
                          class="form-control"
                          disabled
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-9 col-sm-6 col-md-4">
                      <div class="mb-3">
                        วันครบกำหนดชำระ:
                        <date-picker
                          v-model="dueDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          disabled
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="mb-3">
                        หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-5">
                  <div class="row mb-4">
                    <div class="col-12 d-flex justify-content-end">
                      <div
                        class="col-12 col-sm-12 col-md-12 bg-white m-0 text-end"
                        style="border-radius:10px;
                            margin:auto;
                            justify "
                      >
                        <div class="row align-items-center m-0">
                          <div class="col-12">
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ราคารวม :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="totalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPer"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (บาท) :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPrice"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                สุทธิ :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="netPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPer"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (บาท):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2 mb-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                มูลค่ารวม :
                              </div>
                              <div class="col-6 font-size-24">
                                <b-form-input
                                  v-model="grandTotalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end"
                    variant="info"
                    type="submit"
                    @click="updatePayment"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึก
                  </b-button>
                </div>
              </div>

              <div class="row">
                <div class="card mt-4">
                  <div class="p-4 border-top">
                    <form class="needs-validation">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6">
                          <strong class="font-size-18">รายการสินค้า</strong>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-2">
                          <button
                            v-b-modal.modalSearch
                            type="button"
                            class="btn btn-success float-end"
                          >
                            <i class="mdi mdi-plus"></i>
                            เพิ่มรายการสินค้า
                          </button>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-12 col-sm-12 col-md-2 m-2">
                          <strong><code>* </code>ประเภทส่วนลด</strong>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 m-2">
                          <multiselect
                            class="wage"
                            :options="optionTypeDc"
                            v-model="wageItem.dcMethod"
                            @input="getDataWagePart()"
                            :close-on-select="true"
                            :internal-search="true"
                            open-direction="bottom"
                            :show-labels="false"
                            label="name"
                            placeholder=""
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span> 
                          </multiselect>
                        </div>
                      </div> -->

                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ชื่อรายการ</th>
                              <th>ราคา/หน่วย</th>
                              <th>ชั่วโมงงาน</th>
                              <th>มูลค่า</th>
                              <th>ส่วนลด</th>

                              <th>สุทธิ</th>
                              <th class="text-center">ของแถม</th>
                              <!-- <th>ลบ</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(wageItem, index) in rowsWp"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="wage"
                                  :options="wageOptions"
                                  v-model="wageItem.selectWage"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @input="
                                    addNewWage(wageItem.selectWage, wageItem)
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                  style="width: 230px"
                                >
                                  <!-- <span slot="noResult">ไม่พบข้อมูล</span> -->
                                </multiselect>
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  v-model="wageItem.pricePerOne"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpPayment(wageItem)"
                                  class="form-control text-end"
                                  type="number"
                                  style="width: 110px"
                                />
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.amount"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpPayment(wageItem)"
                                  class="form-control text-end"
                                  type="number"
                                  style="width: 60px"
                                />
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.totalPrice"
                                  class="form-control text-end"
                                  style="width: 120px"
                                />
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.dcPer"
                                  @input="totalPricewage(wageItem)"
                                  @keyup.enter="updateWpPayment(wageItem)"
                                  class="form-control text-end"
                                  append=".00"
                                  type="number"
                                  style="width: 70px"
                                />
                                <code
                                  >ได้รับส่วนลด :
                                  <strong>{{ wageItem.dcPrice }}</strong></code
                                >
                              </td>

                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.netPrice"
                                  class="form-control text-end"
                                  style="width: 110px"
                                />
                              </td>
                              <td>
                                <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="wageItem.isPremium"
                                  name="checkbox-1"
                                  value="1"
                                  unchecked-value="0"
                                  @input="updateWpPayment(wageItem)"
                                  class="text-center"
                                >
                                </b-form-checkbox>
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertDelete(wageItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
                <b-modal
                  ref="modalSearch"
                  id="modalSearch"
                  title="ค้นหาค่าแรง/ค่าอะไหล่"
                  hide-footer
                  centered
                  size="xl"
                >
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-12 col-sm-12 col-md-12 align-items-center"
                      >
                        <div class="row align-items-center">
                          <div class="col-12 col-sm-6 col-md-4 mb-4">
                            <code> * </code>ประเภท
                            <multiselect
                              :options="optionTypeWp"
                              v-model="roType"
                              @input="getDataWp(roType)"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder="ค่าแรง/อะไหล่"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>

                          <div class="col-12 col-sm-8 col-md-4 mb-4">
                            รหัสค่าแรง/อะไหล่
                            <b-form-input
                              v-model="filter.wpCode"
                              type="search"
                              placeholder="รหัสค่าแรง/อะไหล่"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>

                          <div class="col-12 col-sm-6 col-md-4 mb-4">
                            ชื่อค่าแรง/อะไหล่
                            <b-form-input
                              v-model="filter.nameTh"
                              type="search"
                              placeholder="ชื่อค่าแรง/อะไหล่"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>

                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              class="btn btn float-end"
                              variant="info"
                              type="submit"
                              @click="handleSearch()"
                            >
                              <i class="uil-search"></i>
                              ค้นหา
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-skeleton-wrapper :loading="loading">
                      <template #loading>
                        <b-skeleton-table
                          :rows="5"
                          :columns="6"
                          :table-props="{ bordered: false, striped: true }"
                          animation="throb"
                        ></b-skeleton-table>
                      </template>
                      <div class="row mt-2">
                        <div class="col-sm-12 col-md-12">
                          <div class="row align-items-center">
                            <div class="col-sm-5 col-md-3">
                              <div
                                id="tickets-table_length"
                                class="dataTables_length"
                              >
                                <label
                                  class="d-inline-block align-items-center"
                                >
                                  แสดงผล
                                  <b-form-select
                                    v-model="perPage"
                                    size="sm"
                                    :options="pageOptions"
                                    @input="handlePageChange"
                                  ></b-form-select
                                  >&nbsp; รายการ
                                </label>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-6"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'W'"
                      >
                        <b-table
                          :items="rowsWage"
                          :fields="fieldsWage"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsWage">
                            {{ rowsWage.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'P'"
                      >
                        <b-table
                          :items="rowsPart"
                          :fields="fieldsPart"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsPart">
                            {{ rowsPart.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'L'"
                      >
                        <b-table
                          :items="rowsLiquid"
                          :fields="fieldsLiquid"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsLiquid">
                            {{ rowsLiquid.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'OS'"
                      >
                        <b-table
                          :items="rowsOs"
                          :fields="fieldsOs"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsOs">
                            {{ rowsOs.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'PROD'"
                      >
                        <b-table
                          :items="rowsProd"
                          :fields="fieldsProd"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsProd">
                            {{ rowsProd.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div
                        class="table-responsive mb-0"
                        v-if="roType.type === 'OTH'"
                      >
                        <b-table
                          :items="rowsOth"
                          :fields="fieldsOth"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          ref="selectableTable"
                          selectable
                          :select-mode="selectMode"
                          @row-selected="onRowSelected"
                        >
                          <template #cell(index)="rowsOth">
                            {{ rowsOth.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <div class="row">
                        <div class="col">
                          หน้า {{ currentPage }} จาก
                          {{ this.totalPage }} ทั้งหมด
                          {{ this.totalRecord }} รายการ
                        </div>

                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRecord"
                                :per-page="perPage"
                                @change="handleChangePage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                      </div>
                    </b-skeleton-wrapper>
                  </div>
                </b-modal>
              </div>

              <!-- <div class="row">
                <div class="col-12">
                  <receiptroComponent
                    :taxInvId="this.taxInvId"
                    :totalPrice="this.totalPrice"
                    :dcPer="this.dcPer"
                    :dcPrice="this.dcPrice"
                    :netPrice="this.netPrice"
                    :vatPer="this.vatPer"
                    :vatPrice="this.vatPrice"
                    :grandTotalPrice="this.grandTotalPrice"
                  ></receiptroComponent>
                </div>
              </div> -->
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <!-- <div class="row">
                    <div class="col-12 col-sm-12 col-md-6">
                      <strong class="font-size-18">รายการชำระเงิน</strong>
                    </div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="col-5 col-sm-6 col-md-3">
                      <div class="mb-3">
                        <code> * </code>ประเภทชำระ:
                        <multiselect
                          v-model="paymentMeth"
                          label="name_th"
                          :options="payment"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6">
                        <strong class="font-size-18">รายการชำระเงิน</strong>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 mb-2">
                        <button
                          v-b-modal.modalAmout
                          type="button"
                          class="btn btn-success float-end"
                        >
                          <i class="mdi mdi-plus"></i>
                          เพิ่มรายการชำระเงิน
                        </button>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>ชื่อรายการ</th>
                            <th>ยอดที่ชำระ</th>
                            <!-- <th>ลบ</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(meth, index) in rowsDetailMeth"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>
                            <td scope="row">
                              <multiselect
                                class="wage"
                                :options="payment"
                                v-model="meth.selectType"
                                :loading="loading"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                @input="updateDetailPayment(meth)"
                                :show-labels="false"
                                label="name_th"
                                placeholder=""
                                style="width: 230px"
                              >
                                <!-- <span slot="noResult">ไม่พบข้อมูล</span> -->
                              </multiselect>
                            </td>
                            <td class="number">
                              <input
                                min="0"
                                v-model="meth.totalPrice"
                                @input="calUpdatePayment(meth)"
                                @keyup.enter="updateDetailPayment(meth)"
                                class="form-control text-end"
                                type="number"
                                style="width: 130px"
                              />
                            </td>
                            <td>
                              <ul class="list-inline mb-0 action">
                                <li class="list-inline-item">
                                  <a
                                    class="px-2 text-danger"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click="alertDeletePayment(meth, index)"
                                  >
                                    <i
                                      class="uil uil-trash-alt font-size-18"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="text-end">
                    <button class="btn btn-danger ms-1" @click="submitPayment" :disabled="status == 'SUBMIT'">
                      ยืนยันการชำระ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal
      ref="modalAmout"
      id="modalAmout"
      title="ช่องทางการชำระเงิน"
      hide-footer
      size="sm"
      centered
      :state="nameState"
    >
      <div class="row">
        <div class="col-12">
          <label> <code> * </code> ประเภทชำระ: </label>
          <multiselect
            v-model="paymentMeth"
            label="name_th"
            :options="payment"
            :show-labels="false"
            open-direction="bottom"
            placeholder=""
          >
            <span slot="noResult">ไม่พบข้อมูล</span>
          </multiselect>
        </div>
        <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
          <label> <code> * </code> ยอดที่ต้องการชำระ</label>
          <input
            style="text-align: center"
            id="poDcPer"
            v-model="paymentTotal"
            @input="calPayment(paymentTotal)"
            type="text"
            class="form-control"
            value="Otto"
          />
          <!-- :class="{
              'is-invalid': submitted && $v.transferCost.$error,
            }"
          <div
            v-if="submitted && $v.transferCost.$error"
            class="invalid-tooltip"
          >
            <span v-if="!$v.transferCost.required">{{ message }}</span>
          </div> -->
        </div>
        <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
          <label> <code> * </code> ยอดรวมทุกช่องทาง</label>
          <input
            style="text-align: center"
            id="poDcPer"
            v-model="paymentMethTotal"
            type="text"
            class="form-control"
            disabled
            value="Otto"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button class="btn btn-primary" @click="addDetailPayment">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
